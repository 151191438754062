import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import scrollTo from "gatsby-plugin-smoothscroll"
import StarRating from "react-svg-star-rating"
import { Link } from "gatsby"
import styled from "styled-components"
import ReviewContainer from "../components/styles/ReviewContainer"
import MainButton from "../components/styles/MainButton"
import RelatedBikes from "../components/RelatedBikes"

class BikeReview extends React.Component {
  render() {
    const affiliateLink = "https://www.roguefitness.com/schwinn-airdyne-pro?a_aid=5e7fb64738ac3"
    const linkText = "$999 from Rogue Fitness (23% Off Now)"

    return (
      <Layout>
        <SEO
          title="Schwinn Airdyne Pro In-Depth Review 2020"
          description="The Airdyne Pro bike introduces updated fan bike technology to deliver an intense cardio workout. Built tough enough for commercial gyms, this bike is perfect for your home workout space."
        />
        <ReviewContainer>

          <div className="r-post">
            <div>
              <aside className="r-article-toc">
                <div className="product-name">
                  <p className="article-type">In-Depth Review</p>
                  <h2>Schwinn Airdyne Pro</h2>
                  <p>Updated January 3rd, 2020</p>
                </div>
                <div>
                  <div className="r-article-toc--btn">Table of Contents</div>
                </div>
                <div className="r-article-toc--contents">
                  <ul>
                    <li>
                      <span onClick={() => scrollTo("#n0")}>Review</span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n1")}>
                        Pros and Cons of Schwinn Airdyne Pro
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n2")}>
                        What sets the Schwinn Airdyne Pro Apart?
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n3")}>
                        Schwinn Airdyne Pro Comparison
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n4")}>
                        Schwinn Airdyne Pro Rating
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n5")}>Where to buy</span>
                    </li>
                  </ul>
                </div>
                <div className="r-article-toc--cta">
                  <MainButton
                    href={affiliateLink}
                    target="_blank"
                  >
                    {linkText}
                  </MainButton>
                </div>
              </aside>
            </div>
            <div className="r-post-content">
              <div className="r-post-container">

                <div className="r-product-box">
                  <div className="product-box-brand">Schwinn Airdyne Pro</div>
                  <div className="product-left">
                    <img
                      src="/images/airdyne/schwinn-airdyne-side.png"
                      alt="Schwinn Airdyne Pro"
                    />
                  </div>
                  <div className="product-right">
                    <a
                      href={affiliateLink}
                      target="_blank"
                    >
                      Schwinn Airdyne Pro
                    </a>
                    <p>
                      The Airdyne® Pro bike introduces updated fan bike
                      technology to deliver an intense cardio workout. Built
                      tough enough for commercial gyms, this bike is perfect for
                      your home workout space.
                    </p>
                    <div>
                      <MainButton
                        href={affiliateLink}
                        target="_blank"
                      >
                        {linkText}
                      </MainButton>
                    </div>
                  </div>
                </div>
                <p className="r-post-intro">
                  The <span className="brand-name">Schwinn Airdyne Pro </span>
                  Bike combines the latest in air resistance technology with
                  unmatched durability and performance, allowing you to reach
                  your full potential in the comfort of your own home.
                </p>

                <span id="n0"></span>
                <h2>Review</h2>
                <p>
                  The Schwinn Airdyne Pro AD8 Bike combines the latest in air
                  resistance technology with unmatched durability and
                  performance, allowing you to reach your full potential in the
                  comfort of your own home. Whether you use it to bring your
                  HIIT workouts to a new level or require the perfect total body
                  cardio workout, the AD8 delivers versatile and challenging
                  workouts.
                </p>
                <p>
                  Schwinn has been manufacturing quality bikes for over a
                  century, and in the fitness world, they’ve been a leading
                  innovator in air resistance technology. With the Airdyne AD
                  Pro, Schwinn has developed its most complete all-purpose
                  stationary bike to date—with a perimeter-weighted flywheel
                  design, powerful solid steel cranks, and doubled-coated steel
                  body construction. Multi-position handles allow the rider to
                  use a vertical or horizontal grip, redesigned foot platforms
                  reduce slip and improve push-off, and a larger, padded seat
                  offers optimal comfort. The universal rail and clamp system
                  even lets users swap in their own customized seats if they
                  choose.
                </p>
                <img
                  src="/images/airdyne/features/schwinn-airdyne-ad8-3.png"
                  alt="Schwinn Airdyne Pro"
                />
                <p>
                  With Schwinn’s unlimited resistance formula, the harder you
                  petal, the more resistance you generate. The more resistance
                  you generate, the more intense your workout.
                </p>
                <p>
                  The Schwinn Airdyne AD Pro is an all in one tool to engage
                  both your upper and lower body for a customized full body
                  workout. Whether your goal is weight loss, toning, or just an
                  overall desire to get healthier, you’ll meet your match with
                  the Schwinn Airdyne Pro.
                </p>
                <span id="n1"></span>
                <h2>Pros and Cons of Schwinn Airdyne Pro</h2>
                <h3>Positives:</h3>
                <ul className="r-content-list-positives">
                  <li>
                    Multi-display LCD console with Calories, Watts, Time,
                    Distance, Speed, RPM, and Heart Rate readouts
                  </li>
                  <li>
                    Telemetric Heart Rate Technology to measure heart rate in
                    real time.
                  </li>
                  <li>
                    Fits Many Body Sizes. The Airdyne Pro has an adjustable seat
                    and handlebars support multiple grip positions.
                  </li>
                  <li>
                    Built-In Fitness Programs. There are nine preset workout
                    programs can help make the most of exercise sessions.
                  </li>
                  <li>
                    High Capacity. The bike weighs 113 LBS and has a maximum
                    user weight capacity of 350 pounds
                  </li>
                </ul>
                <h3>Negatives:</h3>
                <ul className="r-content-list-negatives">
                  <li>One of the most expensive Air Bikes on the market.</li>
                  <li>
                    The Schwinn Airdyne Pro isn't as sturdy and stable as the
                    Rogue Echo Bike.
                  </li>
                  <li>
                    Schwinn’s labor warranty is not as good as some of the
                    competitors.
                  </li>
                </ul>
                <span id="n2"></span>
                <h2>What sets the Assault AirBike Apart?</h2>
                <h3>Assault AirBike Features</h3>
                <h4>1. Single-stage direct drive system</h4>
                <p>
                  For efficient power transfer, low maintenance, and infinite
                  levels of challenge.
                </p>
                <img
                  src="/images/airdyne/features/schwinn-airdyne-front.png"
                  alt="Assault AirBike"
                />

                <h4>2. Multi-display LCD console</h4>
                <p>
                  with Calories, Watts, Time, Distance, Speed, RPM, and Heart
                  Rate readouts.
                </p>
                <img
                  src="/images/airdyne/features/schwinn-airdyne-ad8-feature-console.png"
                  alt="Assault AirBike"
                />

                <h4>3. Multi-position hand grips</h4>
                <p>offer a variety of workout options.</p>
                <img
                  src="/images/airdyne/features/schwinn-airdyne-ad8-feature-handle.png"
                  alt="Assault AirBike"
                />

                <h4>4. Telemetric Heart Rate Technology</h4>
                <p>to measure heart rate in real time.</p>
                <img
                  src="/images/airdyne/features/schwinn-airdyne-ad8-feature-console.png"
                  alt="Assault AirBike"
                />

                <span id="n3"></span>
                <h2>Assault AirBike Comparison</h2>
                <h3>
                  Schwinn Airdyne Pro vs Assault AirBike vs Rogue Echo Bike
                </h3>
                <table>
                  <tbody>
                    <tr>
                      <td className="table-bold">Brand:</td>
                      <td className="table-bold">Schwinn AD Pro</td>
                      <td className="table-bold">Assault AirBike</td>
                      <td className="table-bold">Rogue Echo Bike</td>
                    </tr>
                    <tr>
                      <td>Price:</td>
                      <td>$1,299</td>
                      <td>$999</td>
                      <td>$795</td>
                    </tr>
                    <tr>
                      <td>Weight:</td>
                      <td>113 lbs.</td>
                      <td>98 lbs.</td>
                      <td>127 lbs.</td>
                    </tr>
                    <tr>
                      <td>Drive System:</td>
                      <td>Belt-Driven</td>
                      <td>Chain</td>
                      <td>Belt-Driven</td>
                    </tr>
                    <tr>
                      <td>Foot Pegs:</td>
                      <td>Rotating Plastic/Metal Pegs</td>
                      <td>Knurled Stationary Pegs</td>
                      <td>Rotating Metal Pegs</td>
                    </tr>
                    <tr>
                      <td>Pedals:</td>
                      <td>Metal</td>
                      <td>Plastic</td>
                      <td>Metal</td>
                    </tr>
                    <tr>
                      <td>Monitor Presets:</td>
                      <td>Time, Distance, Calories, Intervals, Heart Rate</td>
                      <td>Time, Distance, Calories, Intervals, Heart Rate</td>
                      <td>Time, Distance, Calories, Intervals, Heart Rate</td>
                    </tr>
                    <tr>
                      <td>Pedals:</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Adjustments:</td>
                      <td>Forward, Back, Up, Down Seat Adjustments</td>
                      <td>Forward, Back, Up, Down Seat Adjustments</td>
                      <td>Forward, Back, Up, Down Seat Adjustments</td>
                    </tr>
                    <tr>
                      <td>Length:</td>
                      <td>42"</td>
                      <td>50.9"</td>
                      <td>58.875"</td>
                    </tr>
                    <tr>
                      <td>Width:</td>
                      <td>20"</td>
                      <td>23.3"</td>
                      <td>29.875"</td>
                    </tr>
                    <tr>
                      <td>Height:</td>
                      <td>52"</td>
                      <td>48.4"</td>
                      <td>52.75"</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <img src="/images/airbike-prices.png" className="price-graph" alt="Air bike prices graph"/>
                <p>
                  All Air Bikes come fully equipped with very good LCD console
                  monitors that display many various and important metrics for
                  the rider.
                </p>
                <img
                  src="/images/airdyne/airbike-console-comparison.png"
                  alt="Assault AirBike"
                />
                <br />
                <h4>Schwinn Airdyne Pro</h4>
                <p>
                  The Schwinn Airdyne Pro come with a very large and readable
                  LCD console. You’ll be able to select or create many
                  customized workouts which include intervals, target, and heart
                  rate tracking. The LCD tracks many various metrics like
                  Calories, Watts, Time, Distance, Speed, RPM, and Heart Rate
                  readouts.
                </p>
                <h4>Assault AirBike</h4>
                <p>
                  The Assault AirBike comes with one of the best LCD display
                  consoles featured on Air Bikes currently. Athletes can
                  simultaneously track their heart rate, speed, RPM, time,
                  calories burned, and more. Even watts can be monitored with
                  precision accuracy, thanks to the AirBike’s 20-horsepower
                  electric motor and carefully calibrated torque measurement
                  meter.
                </p>

                <h4>Rogue Echo Bike</h4>
                <p>
                  The Rogue Echo Bike also comes equipped with a mounted 6” LCD
                  console and features a crystal clear, high contrast display.
                  You’ll be able to select or create from a wide range of modes,
                  including intervals, Target (Time, Distance, or Calories), and
                  Heart Rate Tracking (requires heart rate transmitter to send
                  data). The LCD console runs on just two AA batteries, which
                  are included. The console also has some quick select buttons
                  on the right side of the monitor which makes it easy to see
                  during use.
                </p>
                <span id="n4"></span>
                <h2>Schwinn Airdyne Pro Rating</h2>
                <h3>
                  Schwinn Airdyne Pro -{" "}
                  <span className="score-rating">4.0</span>
                </h3>
                <StarRating
                  isHalfRating={true}
                  isReadOnly
                  initialRating={4.0}
                />
                <div className="rating__graphs" width="100%">
                  <div>
                    <p>Construction - 4.0</p>
                    <progress value="4" max="5"></progress>
                  </div>
                  <div>
                    <p>Durability 4.0</p>
                    <progress value="4" max="5"></progress>
                  </div>
                  <div>
                    <p>Performace - 4.0</p>
                    <progress value="4" max="5"></progress>
                  </div>
                  <div>
                    <p>Aesthetics - 3.8</p>
                    <progress value="3.8" max="5"></progress>
                  </div>
                  <div>
                    <p>Customization - 4</p>
                    <progress value="4" max="5"></progress>
                  </div>
                  <div>
                    <p>Adjustability - 4.0</p>
                    <progress value="4" max="5"></progress>
                  </div>
                  <div>
                    <p>Value - 4.0</p>
                    <progress value="4" max="5"></progress>
                  </div>
                </div>
                <p>
                  The Schwinn Airdyne AD Pro is an all in one tool to engage
                  both your upper and lower body for a customized full body
                  workout. Whether your goal is weight loss, toning, or just an
                  overall desire to get healthier, you’ll meet your match with
                  the Schwinn Airdyne Pro.
                </p>
                <span id="n5"></span>
                <h2>Where to buy</h2>
                <div className="r-product-box">
                  <div className="product-box-brand">Schwinn Airdyne Pro</div>
                  <div className="product-left">
                    <img
                      src="/images/airdyne/schwinn-airdyne-side.png"
                      alt="Schwinn Airdyne Pro"
                    />
                  </div>
                  <div className="product-right">
                    <a
                      href={affiliateLink}
                      target="_blank"
                    >
                      Schwinn Airdyne Pro
                    </a>
                    <p>
                      The Airdyne® Pro bike introduces updated fan bike
                      technology to deliver an intense cardio workout. Built
                      tough enough for commercial gyms, this bike is perfect for
                      your home workout space.
                    </p>
                    <div>
                      <MainButton
                        href={affiliateLink}
                        target="_blank"
                      >
                        {linkText}
                      </MainButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReviewContainer>
        <RelatedBikes 
          bike1="Assault Airbike"
          bike1img="/images/assault-airbike.jpg"
          bikeLink1="/assault-airbike-review"

          bike2="Concept2 BikeErg"
          bike2img="/images/bike-erg.jpg"
          bikeLink2="/concept2-bike-erg-review"

          bike3="Rogue Echo Bike"
          bike3img="/images/rogue-echo-bike.jpg"
          bikeLink3="/rogue-echo-bike-review"
        />
      </Layout>
    )
  }
}

export default BikeReview
